@import "../../../../../sass/imports";

.preferences-form .admin-nutrition-editor {
    width: 100%;
    color: $text-gray;

    input[type="number"] {
        text-align: right;
        font-family: monospace;
        margin-bottom: 8px;
        border: 1px solid rgba($bluey-gray, 0.5);

        &[data-available="true"] {
            border: 1px solid $bluey-gray;
        }

        &[data-error="true"] {
            border: 1px solid red;
        }

        &:disabled {
            border: none;
        }
    }

    .input-flex {
        display: flex;
        align-items: center;
        white-space: nowrap;

        > input {
            flex-grow: 4;
        }

        > span, > button {
            white-space: nowrap;
            display: inline-block;
            flex-shrink: 1;
            font-size: 12px;
            padding: 0 0 0 3px;
            border: none;
            background: none;
        }

        > button {
            color: $raspberry;
            text-decoration: underline;
        }
    }

    .nutrient-group {
        margin: 0 0 30px 0;

        ul {
        }

        li {
            display: inline-block;
            vertical-align: top;
            width: 145px;
            margin: 5px;
            text-align: left;

            > label {
                font-size: 11px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 0 5px;
            text-transform: uppercase;
        }
    }
}

.admin-nutrition-editor {
    .google-link-text {
        font-size: 12px;

        > p {
            display: inline-block;
            color: $raspberry;
            cursor: pointer;
        }
    }

    .show-more-toggle {
        .sub-action-btn {
            text-decoration: none;
            font-weight: bold;
            font-size: 12px;
            margin: 10px 0 5px;

            i {
                vertical-align: bottom;
            }
        }
    }
}

.el-list-form .admin-nutrition-editor {
    width: 100%;
    color: $text-gray;

    > p, .google-link-text {
        width: 75%;
        text-align: left;
        margin: 15px auto;
        font-size: 12px;
        display: block;
    }

    .input-flex {
        display: flex;
        white-space: nowrap;

        > input {
            flex-grow: 4;
            width: 100%;
        }

        > span, > button {
            white-space: nowrap;
            display: inline-block;
            flex-shrink: 1;
            font-size: 14px;
            padding: 0 0 0 3px;
            border: none;
            background: none;
            height: 30px;
        }
    }

    .nutrient-group {
        margin: 0 0 30px 0;

        ul {
        }

        > ul > li {
            display: inline-block;
            vertical-align: top;
            min-width: 145px;
            margin: 0 5px 0 0;
            text-align: left;
            width: calc(50% - 5px);

            > label {
                font-size: 11px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 100%;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &:last-child {
            .select-dropdown {
                top: 0;
            }
        }
    }

    &[data-recipe="true"] {
        color: #324253;
        background-color: #edeff2;
        padding: 15px;

        @include max-screen(47em) {
            width: 100%;
            left: -40px;
        }

        .input-flex {
            float: right;
            > input {
                background: none;
                border: none;
                max-width: 100px;
            }

            input[type="number"] {
                &[data-available="true"] {
                    border: none;
                }
                &:focus {
                    @include placeholder {
                        color: transparent;
                    }
                }
            }

            > button {
                font-size: 14px;
            }

            p {
                display: inline-block;
                line-height: 42px;
            }

            .select-container {
                width: auto;
                background-color: none;
                border-radius: 40px;
                padding: 0 20px 0 10px;
                cursor: pointer;
                float: right;
                text-alignt: right;

                .select-dropdown {
                    width: 100px;
                    left: -5px;

                }

                .value {
                    color: #324253;
                    font-size: 14px;
                }
            }

            .no-dropdown {
                display: inline-block;
                line-height: 42px;
                margin-left: 10px;
                margin-right: 20px;
                vertical-align: super;
            }
        }

        .nutrient-group {
            margin: 0;

            li {
                display: block;
            }

            > ul {
                > li {
                    height: 50px;
                    width: calc(100% - 5px);
                    border-bottom: 1px solid rgba(129, 144, 159, 0.2);

                    &[data-indent="1"] {
                        margin-left: 30px;
                        width: calc(100% - 35px);
                    }

                    &[data-indent="2"] {
                        margin-left: 60px;
                        width: calc(100% - 65px);
                    }

                    > * {
                        display: inline-block;
                    }

                    > label {
                        font-size: 14px;
                        font-weight: normal;
                        width: fit-content;
                        line-height: 50px;
                        text-transform: none;
                    }
                }
            }

            .select-container {
                width: 100%;
                background-color: none;
                @include border-radius(40px);
                cursor: pointer;

                &[data-focus="true"] {
                    box-shadow: none;
                }

                .value {
                    color: $raspberry;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;

                    @include min-screen(47em) {
                        font-size: 16px;
                    }
                }

                .placeholder {
                    color: #81909f;
                    text-align: center;
                    text-transform: none;
                    font-weight: normal;
                    font-size: 14px;

                    @include min-screen(47em) {
                        font-size: 16px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    input[type="number"] {
        text-align: right;
        font-family: monospace;
        border: 1px solid rgba($bluey-gray, 0.5);
        color: $raspberry;
        padding: 20px 5px 0;

        &[data-available="true"] {
            border: 1px solid $bluey-gray;
        }

        &[data-error="true"] {
            border: 1px solid red;
        }

        &:disabled {
            border: none;
        }
    }
}
