@import '../../../sass/imports';

.el-push-notification-prompt {
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        font-size: 20px;

        i {
            font-size: 4rem;
            color: #a30063;
        }
    }

    h2 {
        color: #425569;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 40px;
        text-align: center;
    }

    p {
        color: #425569;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
    }

    li {
        align-items: center;
        display: flex;
        justify-content: center;
        background: #ffe5f5;
        background: linear-gradient(193deg, #ffe5f5, #ffcceb);
        padding: 20px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        margin: 20px 0;
        gap: 20px;
        box-shadow:
            inset 1px 1px 0px rgba(0, 0, 0, 0.05),
            1px 1px 0px rgb(255, 255, 255, 0.5);
    }

    .icon {
        background: #fff;
        border-radius: 80px;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 80px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .icon i {
        font-size: 40px;
        color: #a10b63;
    }

    .text {
        color: #425569;
        font-size: 15px;
    }

    .hide {
        display: none;
    }
}
