
@import "../../../../../sass/imports";

.close-overlay {
    position: absolute;
    z-index: 1;
    color: transparent;
    top: 0; bottom: 0; height: 100vh;
    left: 0; right: 0; width: 100vh;
}

.search-types-modal-container {
    max-height: 0;
    overflow: hidden;
    background-color: white;
    @include border-radius(10px);

    position: absolute;
    z-index: 5;
    bottom: 120px;
    left: 10px;
    width: calc(100vw - 20px);

    @include transition(.333s all ease-in-out);
    max-height: 0;

    &[data-drawer="true"] {
        max-height: 45em;
    }

    .close-modal-btn {
        width: 100%;
        text-align: center;
        padding: 2px 10px;
        color: #d4d3d8;
        background: none;
        border: none;
        border-bottom: 1px solid #d8d8d8;
    }
}

.search-type-popover .dropdown-btn, .choose-search-type-btn  {
    @include border-radius(4px);
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));
    border: solid 0.5px rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(170deg, #da67ad, #b7498c);
    padding: 10px 20px;
    text-align: left;
    font-size: 15px;
    color: white;

    width: 100%;
    max-width: 335px;
    margin: auto;
    display: block;

    i {
        color: $raspberry;
        font-size: 22px;
        margin: 0 20px 0 0;
        vertical-align: middle;
        display: none;
    }

    .icon-search5 {
        font-size: 21px;
    }
}

.choose-search-type-btn {
    &:after {
        @include icon-font;
        content: $icon-chevron-down;
        float: right;
    }
}

.search-type-popover {
    .dropdown-btn {
        width: 280px;
        @include max-screen(47em) {
            width: 335px;
        }
    }

    &.date-selector-popover {
        .dropdown-btn {
            @include max-screen(47em) {
                width: 280px;

            }
        }
    }

    .triangle, .triangle2 {
        display: none;
    }

    .dropdown-container {
        @include max-screen(47em) {
            width: 100%;
            max-width: 335px;
            margin: auto;
            display: block;
        }
    }

    .dropdown-dropdown {
        @include transform(translate(-50%, 0px) !important);
    }

    .dropdown-content {
        padding: 0 !important;
        border-radius: 10px;
        min-width: 280px;
        margin-top: 0 !important;

        @include max-screen(47em) {
            min-width: 335px;;
        }
    }

    .close-btn {
        display: none;
    }
}
