@import '../../../sass/imports';

.el-prompt {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .card {
        background: #fafafa;
        background: linear-gradient(337deg, #f3f3f3, #ffffff);
        width: 90%;
        max-width: 500px;
        max-height: 90vh;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        @media (max-width: 1200px) {
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }
    }

    .content {
        padding: 40px;
        overflow-y: auto;
        flex: 1;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px 24px;
        background: white;
        border-top: 1px solid #e9e9e9;

        .link {
            border: none !important;
        }
    }

    .button {
        padding: 12px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        text-align: center;
    }

    .button.default {
        background: #007bff;
        color: white;
        border-radius: 6px;
    }

    .button.outline {
        background: transparent;
        border: 1px solid #007bff;
        color: #007bff;
        border-radius: 6px;
    }
}
